<!-- 学生列表 -->
<!-- 学校花名册 -->
<template>
     <div class="loadr">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >花名册管理</el-breadcrumb-item>
            <el-breadcrumb-item >上传花名册</el-breadcrumb-item>
        </el-breadcrumb>
      
        <el-upload
                ref="upload"
                class="elupload"
                drag
                :limit="1" 
                action=""
                :show-file-list="false"
                :auto-upload="true"
                :before-upload="beforeUpload"
                :http-request="httprequest"
                >
                
          <div class="upload">
            <i style="font-size:80px" class="el-icon-tickets"></i>
            <p style="font-size:20px">点击上传excel文件</p>
          </div>
          
        </el-upload>  
        <div class="dwtemplate" @click="downloadTemplate">下载花名册模板</div>
        <el-progress v-show="showprocess" type="line" color="#00cccc" :stroke-width="20" :percentage="progressPercent" class="progress" :text-inside="true"></el-progress>
       
     </div>
</template>
<script>
import {uploadRoster} from '@/api/roster.js'
export default {
    name:'userList',

    data(){
        return{
            showprocess:false,//进度条
            progressPercent:0
        }
    },
    methods:{
        //
        beforeUpload(file){
           // console.log("上传",file) ;
            var msg=file.name.substring(file.name.lastIndexOf('.')+1);
            const fileType1 = msg==='xls';
            const fileType2 = msg==='xlsx';
            if(!fileType1 && !fileType2){
                this.$message({
                type:'warning',
                 message:'只能上传excel格式文件'
                 })
                 return fileType ;
            }              
        },

        httprequest(par){
            var uploadFile = par.file;
            var formData = new FormData();
            formData.append('file',uploadFile);
            //自定义上传进度条 采用 axios自带的 onUploadProgress 来实现
            const uploadProgressEvent = progressEvent => {
              this.progressPercent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            };
            this.showprocess=true;//开启进度条
            uploadRoster(
                formData,
                uploadProgressEvent
            ).then(res=>{
                if(res.data.status == 200){
                    this.$message({
                     message: '上传成功！',
                     type: 'success'
                    });
                    this.$refs.upload.clearFiles(); //上传成功后要对浏览器进行清除后才能再次上传
                    this.showprocess=false;//关闭进度条
                }else{
                    this.$message.error(res.data.message);
                }
              
            }).catch(res=>{
                this.$message.error(res);
                this.$refs.upload.clearFiles();
                this.showprocess=false;
        })
        },
        //下载花名册模板
        downloadTemplate(){
            console.log("下载花名册模板");
            window.open('https://sz.drbodyhealth.com/student-template.xls');
        }
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.el-icon-tickets:before{
    color: #00cccc !important
}
/deep/.progress{
    width: 640px;
    height: 100px;
    margin: 0 auto
  }

.loadr
    overflow hidden
.elupload
    width: 1000px !important
    margin: 60px auto 0 auto !important
    

.dwtemplate
    color: #00cccc
    cursor pointer
    display: block
    width: 1000px;
    margin: 0 auto
   
</style>